/*------    Ombre -------*/
import shadow from "../img/players/shadow.png";


/*------   Enzo Goudou    -------*/
import goudouProfile from "../img/players/goudou/enzo-goudou.webp";

/*------   Lucas Boucaud    -------*/
import boucaudProfile from "../img/players/boucaud/lucas-boucaud.webp";
import boucaudMP from "../img/players/boucaud/Lucas_Boucaud.webp";

/*------   William Pfister    -------*/
import williamProfile from "../img/players/pfister/william-pfister.webp";
import pfisterMP from "../img/players/pfister/William_Pfister.webp";

/*------   Loic Schwartz    --------*/
import schwartzProfil from "../img/players/schwartz/loic-schwartz.webp"
import schwartzMP from "../img/players/schwartz/Loic_Schwartz.webp";

/*------ Dominik  ---------*/
import dominikProfile from '../img/players/dominik/dominik-olejniczak.webp';

/*----   Noah Kirkwood   -----*/
import kirkwoodProfil from '../img/players/kirkwood/noah-kirkwood.webp';

/*---- Giovan Oniangue   -----*/

import oniangueProfil from '../img/players/oniangue/giovan-oniangue.webp';

/*---- Marcus Santos Silva   -----*/
import silveraProfil from '../img/players/santos-silva/marcus-santos-silva.webp';

/*---- Mohamed Diakite   -----*/
import diakiteProfil from '../img/players/diakite/mohamed-diakite.webp';

/*----   Jerome Robinson   -----*/
import robinsonProfil from '../img/players/robinson/jerome-robinson.webp';

/*----   Khalid Moore   -----*/
import mooreProfil from '../img/players/moore/khalid-moore.webp';

/*----   Nolan Traore   -----*/
import traoreProfil from '../img/players/traore/nolan-traore.webp';

export const playersData = 
[
    {
        id: 1,
        firstName: "Lucas",
        lastName: "Boucaud",
        position: "Meneur",
        number: "10",
        age: 22,
        birth: "le 30 août 2000",
        birthPlace: "-",
        size: "1,88",
        teamPresence: "2021",
        country: "FRANCE",
        profilImg: boucaudProfile,
        mainPageImg: boucaudMP,
        smallResume: "-",
        resume: "-",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall ",
                    comeIn: "2021",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Etoile Angers Basket",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "NM1"
                },
                {
                    club: " Vendee Challans Basket",
                    comeIn: "2018",
                    ending: "2020",
                    leagueName: "NM3 & NM1"
                }
            ],
            amateur: [
                {
                    club: "Centre Fédéral",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: ""
                },
                {
                    club: "US Ris-Orangis",
                    comeIn: "2015",
                    ending: "2018",
                    leagueName: ""
                },
                {
                    club: "ES Montgeron Basket",
                    comeIn: "2012",
                    ending: "2015",
                    leagueName: ""
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: "",
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: "",
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 2,
        firstName: "William ",
        lastName: "Pfister",
        position: "Ailier Fort",
        number: "5",
        age: 28,
        birth: "le 5 janvier 1995",
        birthPlace: "-",
        size: "2,02",
        teamPresence: "2020",
        country: "FRANCE",
        profilImg: williamProfile,
        mainPageImg: pfisterMP,
        smallResume: "William Pfister, né le 5 janvier 1995, est un joueur professionnel français de basket-ball. Il mesure 2,02 m",
        resume: "À la fin de la saison 2019-2020 passée au Caen Basket Calvados (NM1), William Pfister fait valoir sa clause lui permettant de quitter Caen en cas de non-montée en Pro B. Il signe au Saint-Quentin Basket-Ball en Pro B pour la saison 2020-20212.\n" +
            "Le 4 juin 2022, William Pfister signe pour une 3e saison avec Saint-Quentin Basket-Ball",
        carrier: {
            pro: [

                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2020",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                },
                {
                    club: "Caen Basket Calvados ",
                    comeIn: "2019",
                    ending: "2020",
                    leagueName: "NM1"
                },
                {
                    club: "Golbey Epinal Thaon Vosges",
                    comeIn: "2018",
                    ending: "2019",
                    leagueName: "NM1"
                },
                {
                    club: "Florida Atlantic University",
                    comeIn: "2016",
                    ending: "2018",
                    leagueName: "NCAA"
                },
                {
                    club: "Northwest Florida State College",
                    comeIn: "2014",
                    ending: "2016",
                    leagueName: "JUCO"
                },
                {
                    club: "BC Montbrison",
                    comeIn: "2013",
                    ending: "2014",
                    leagueName: "NM2"
                }, {
                    club: "JL Bourg",
                    comeIn: "2012",
                    ending: "2013",
                    leagueName: "PRO B"
                },
            ],
            amateur: [
                {
                    club: "JL Bourg",
                    comeIn: "2011",
                    ending: "2012",
                    leagueName: "U21"
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: "",
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: "",
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 3,
        firstName: "Loïc",
        lastName: "Schwartz",
        position: "Arrière",
        number: "0",
        age: 30,
        birth: "le 4 décembre 1992",
        birthPlace: "-",
        size: "1,97",
        teamPresence: "2022",
        country: "BELGIQUE",
        profilImg: schwartzProfil,
        mainPageImg: schwartzMP,
        smallResume: "Loïc Schwartz, né le 4 décembre 1992, est un joueur professionnel international belge de basket-ball. Il mesure 1,98 m.",
        resume: "Il commence sa carrière professionnelle en Belgique en 2014 avec l'équipe de Spirou Charleroi Basket Club. À la fin de la saison 2017, il rejoint l'équipe du Filou Oostende où il évolue pendant 4 saisons.\n" +
            "\n" +
            "En 2021, il signe avec l'équipe grecque de Promithéas Patras qu'il quitte en cours de saison pour rejoindre Orléans Loiret Basket.\n" +
            "\n" +
            "Il arrive à Saint-Quentin en octobre 2022 en tant que pigiste2.\n" +
            "\n" +
            "En février 2023, il est sélectionné pour disputer avec l'équipe de Belgique les deux derniers matchs des éliminatoires de Coupe du monde 20233.",
        carrier: {
            pro: [

                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2022",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Orléans Loiret Basket",
                    comeIn: "2022",
                    ending: "2022",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "ASP Promithéas Patras",
                    comeIn: "2021",
                    ending: "2022",
                    leagueName: "D1 Grèce & Eurocup"
                },
                {
                    club: "Filou Oostende",
                    comeIn: "2017",
                    ending: "2021",
                    leagueName: "D1 Belgique, FIBA Europe Cup & Basketball Champions League"
                },
                {
                    club: "Spirou Charleroi Basket Club",
                    comeIn: "2014",
                    ending: "2017",
                    leagueName: "D1 Belgique, FIBA Europe Cup & Basketball Champions League"
                }, {
                    club: "VOO Verviers-Pepinster",
                    comeIn: "2013",
                    ending: "2014",
                    leagueName: "D1 Belgique"
                },
                {
                    club: "Dexia Mons-Hainaut",
                    comeIn: "2010",
                    ending: "2013",
                    leagueName: "D1 & D2 Belgique"
                },
            ],
            amateur: [
                {
                    club: "",
                    comeIn: "",
                    ending: "",
                    leagueName: ""
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: "",
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: "",
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 4,
        firstName: "Dominik",
        lastName: "Olejniczak",
        position: "Pivot",
        number: "13",
        age: 27,
        birth: "1er juillet 1996",
        birthPlace: "Toruń (Pologne)",
        size: "2.13",
        teamPresence: "2023",
        country: "POLOGNE",
        profilImg: dominikProfile,
        mainPageImg: dominikProfile,
        smallResume: "Dominik Olejniczak, né le 1er juillet 1996 à Toruń en Couïavie-Poméranie, est un joueur polonais de basket-ball. Il évolue au poste de pivot.",
        resume: "Entre 2012 et 2015, il joue au SMS PZKosz Wladyslawowo en ligue polonaise 2.\n" +
            "\n" +
            "En 2015, il part aux États-Unis pour suivre un cursus universitaire américain et intégrer une équipe NCAA. Il intègre l'Université Drake en Iowa et joue pour l'équipe de basket-ball des Bulldogs de Drake. En 30 matches, il a des moyennes de 6,5 points et 4,3 rebonds par match. Avec une efficacité aux tirs à deux points à 72,2%, il est le deuxième meilleur joueur le plus adroit de toute la division I de NCAA au cours de la saison 2015-2016.\n" +
            "\n" +
            "Après cette première saison américaine, il décide de s'installer à l'Université du Mississippi où il s'entraîne avec les Rebels d'Ole Miss et joue en championnat à partir de la saison 2017-2018.\n" +
            "\n" +
            "Le 6 juillet 2021, il signe un contrat de deux ans avec le BCM Gravelines-Dunkerque1.\n" +
            "\n" +
            "Le 24 juin 2023, il rejoint le promu Saint-Quentin2.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                },
                {
                    club: "BCM Gravelines-Dunkerque",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Trefl Sopot",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "D1 Pologne"
                }
            ],
            amateur: [
                {
                    club: "Florida State University",
                    comeIn: "2019",
                    ending: "2020",
                    leagueName: "NCAA"
                },
                {
                    club: "The University of Mississippi",
                    comeIn: "2016",
                    ending: "2019",
                    leagueName: "NCAA"
                },
                {
                    club: "Drake University",
                    comeIn: "2015",
                    ending: "2016",
                    leagueName: "NCAA"
                },
                {
                    club: "SMS PZKosz Wladyslawowo",
                    comeIn: "2012",
                    ending: "2015",
                    leagueName: ""
                },
                {
                    club: "TKM Wloclawek",
                    comeIn: "2011",
                    ending: "2012",
                    leagueName: "U16"
                }
            ],
            palmares: [
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: "",
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: "",
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }, 
    {
        id: 6,
        firstName: "Enzo",
        lastName: "Shahrvin",
        position: "Pivot",
        number: "18",
        age: "22",
        birth: " ",
        birthPlace: "",
        size: "2m00",
        teamPresence: "",
        country: "FRANCE",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "",
        resume: "",
        carrier: {
            pro: [
                {
                    club: "",
                    comeIn: "",
                    ending: "-",
                    leagueName: ""
                },
            ],
            amateur: [
                {
                    club: "",
                    comeIn: "",
                    ending: "",
                    leagueName: ""
                }
            ],
            palmares: [
            ],

        },
        pictures: [
            /*{
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: "",
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: "",
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },*/
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 7,
        firstName: "Noah",
        lastName: "Kirkwood",
        position: "Arrière",
        number: "22",
        age: 24,
        birth: "27 décembre 1999",
        birthPlace: "Kanata - Canada",
        size: "2.01",
        teamPresence: "2024",
        country: "CANADA",
        profilImg: kirkwoodProfil,
        mainPageImg: kirkwoodProfil,
        smallResume: "Noah Kirkwood est un joueur de basketball né le 27 décembre 1999. Il mesure 2m01 et évolue au poste d’arrière.",
        resume: "Noah Kirkwood commence sa carrière universitaire sous le maillot de l’équipe d'Harvard Crimson. Après 3 saisons complètes en NCAA, l’arrière canadien rallie le Vieux Continent pour commencer sa carrière professionnelle à Bonn, en Allemagne, où il découvre également la Coupe d'Europe avec la BCL.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Telekom Baskets Bonn",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "Basketball-Bundesliga (BBL)"
                },
                {
                    club: "Harvard Crimson",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [1],
                title: "Action shot",
                tag: ["Match"],
                src: shadow,
                legend: "Noah Kirkwood en action",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 8,
        firstName: "Giovan",
        lastName: "Oniangue",
        position: "Ailier",
        number: "4",
        age: 33,
        birth: "24 avril 1991",
        birthPlace: "Brazzaville - Congo",
        size: "1.97",
        teamPresence: "2024",
        country: "FRANCE",
        profilImg: oniangueProfil,
        mainPageImg: oniangueProfil,
        smallResume: "Giovan Oniangue est un joueur de basketball né le 24 avril 1991. Il mesure 1m97 et évolue au poste d’ailier.",
        resume: "Après 7 saisons avec les Metropolitans 92, Giovan a navigué dans plusieurs clubs de première division française. Après trois saisons à Orléans et une à Dijon, il rejoint Saint-Quentin Basket-ball.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "JDA Dijon",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Pau-Lacq-Orthez",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Orléans",
                    comeIn: "2018",
                    ending: "2021",
                    leagueName: "Pro B & Betclic Élite"
                },
                {
                    club: "BCM Gravelines-Dunkerque",
                    comeIn: "2018",
                    ending: "2018",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Boulazac",
                    comeIn: "2017",
                    ending: "2018",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Metropolitans 92",
                    comeIn: "2010",
                    ending: "2017",
                    leagueName: "Betclic Élite"
                }
            ]
        },
        pictures: [
            {
                playerId: [2],
                title: "Training",
                tag: ["Entraînement"],
                src: shadow,
                legend: "Giovan Oniangue à l'entraînement",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    // {
    //     id: 9,
    //     firstName: "Marcus",
    //     lastName: "Santos Silva",
    //     position: "Pivot",
    //     number: "14",
    //     age: 27,
    //     birth: "7 juin 1997",
    //     birthPlace: "Boston - USA",
    //     size: "2.02",
    //     teamPresence: "2024",
    //     country: "USA",
    //     profilImg: silveraProfil,
    //     mainPageImg: silveraProfil,
    //     smallResume: "Marcus Santos Silva est un joueur de basketball né le 7 juin 1997. Il mesure 2m02 et évolue au poste de pivot.",
    //     resume: "Marcus a un parcours unique. Après 4 saisons en NCAA, il a brièvement signé en NFL pour jouer au football américain avant de revenir au basketball en 2023 du côté de Lille.",
    //     carrier: {
    //         pro: [
    //             {
    //                 club: "Saint-Quentin BasketBall",
    //                 comeIn: "2024",
    //                 ending: "-",
    //                 leagueName: "Betclic Élite"
    //             },
    //             {
    //                 club: "Lille",
    //                 comeIn: "2023",
    //                 ending: "2024",
    //                 leagueName: "Pro B"
    //             },
    //             {
    //                 club: "Texas Tech Red Raiders",
    //                 comeIn: "2020",
    //                 ending: "2022",
    //                 leagueName: "NCAA"
    //             },
    //             {
    //                 club: "VCU Rams",
    //                 comeIn: "2017",
    //                 ending: "2020",
    //                 leagueName: "NCAA"
    //             }
    //         ]
    //     },
    //     pictures: [
    //         {
    //             playerId: [3],
    //             title: "Match highlights",
    //             tag: ["Match"],
    //             src: shadow,
    //             legend: "Marcus Santos Silva en match",
    //             date: "",
    //             link: "",
    //             author: "",
    //             persons: []
    //         }
    //     ],
    //     sci: {
    //         facebook: "",
    //         instagram: "",
    //         tweeter: "",
    //         linkedIn: ""
    //     }
    // },
    {
        id: 9,
        firstName: "Mohamed",
        lastName: "Diakite",
        position: "Ailier / Ailier fort",
        number: "1",
        age: 18,
        birth: "8 avril 2006",
        birthPlace: "Poitiers",
        size: "2.05",
        teamPresence: "2024",
        country: "FRANCE",
        profilImg: diakiteProfil,
        mainPageImg: diakiteProfil,
        smallResume: "Mohamed Diakite est un joueur de basketball né le 8 avril 2006. Il mesure 2m05 et évolue au poste d’ailier / ailier fort.",
        resume: "Après trois saisons disputées en troisième division française avec le centre de formation de Pôle France, Mohamed rejoint Saint-Quentin Basket-ball pour débuter sa carrière professionnelle.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Pôle France",
                    comeIn: "2021",
                    ending: "2024",
                    leagueName: "NM1"
                }
            ]
        },
        pictures: [
            {
                playerId: [4],
                title: "Action shot",
                tag: ["Match"],
                src: shadow,
                legend: "Mohamed Diakite lors d'un match",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 10,
        firstName: "Jerome",
        lastName: "Robinson",
        position: "Arrière / Ailier",
        number: "9",
        age: 27,
        birth: "22 février 1997",
        birthPlace: "Raleigh - USA",
        size: "1.98",
        teamPresence: "2024",
        country: "USA",
        profilImg: robinsonProfil,
        mainPageImg: robinsonProfil,
        smallResume: "Jerome Robinson est un joueur de basketball né le 22 février 1997. Il mesure 1m99 et évolue au poste d’arrière / ailier.",
        resume: "Jerome fera sa première expérience en dehors des États-Unis cette année sous les ordres de Julien Mahé. Il a joué pour plusieurs équipes NBA, dont les Golden State Warriors.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Golden State Warriors",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "NBA"
                },
                {
                    club: "Santa Cruz Warriors",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "NBA G-League"
                },
                {
                    club: "Washington Wizards",
                    comeIn: "2019",
                    ending: "2021",
                    leagueName: "NBA"
                },
                {
                    club: "Los Angeles Clippers",
                    comeIn: "2018",
                    ending: "2020",
                    leagueName: "NBA"
                },
                {
                    club: "Ontario Clippers",
                    comeIn: "2018",
                    ending: "2019",
                    leagueName: "NBA G-League"
                },
                {
                    club: "Boston College Eagles",
                    comeIn: "2015",
                    ending: "2018",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [5],
                title: "Training session",
                tag: ["Entraînement"],
                src: shadow,
                legend: "Jerome Robinson à l'entraînement",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 11,
        firstName: "Khalid",
        lastName: "Moore",
        position: "Ailier fort",
        number: "12",
        age: 24,
        birth: "29 juillet 2000",
        birthPlace: "Mineola - USA",
        size: "2.01",
        teamPresence: "2024",
        country: "USA",
        profilImg: mooreProfil,
        mainPageImg: mooreProfil,
        smallResume: "Khalid Moore est un joueur de basketball né le 29 juillet 2000. Il mesure 2m01 et évolue au poste d’ailier fort.",
        resume: "Après 5 années en NCAA, Khalid a commencé sa carrière professionnelle en Grèce, attirant ensuite l'attention de Saint-Quentin pour la saison 2024-2025.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Lavrio",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "A1 Ethniki"
                },
                {
                    club: "Fordham Rams",
                    comeIn: "2022",
                    ending: "2023",
                    leagueName: "NCAA"
                },
                {
                    club: "Georgia Tech Yellow Jackets",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [6],
                title: "Match action",
                tag: ["Match"],
                src: shadow,
                legend: "Khalid Moore en pleine action lors d'un match",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 12,
        firstName: "Nolan",
        lastName: "Traore",
        position: "",
        number: "2",
        age: "",
        birth: "",
        birthPlace: "",
        size: "",
        teamPresence: "",
        country: "",
        profilImg: traoreProfil,
        mainPageImg: traoreProfil,
        smallResume: "",
        resume: "",
        carrier: {
            pro: [
                {
                    club: "-",
                    comeIn: "-",
                    ending: "-",
                    leagueName: "-"
                }
            ]
        },
        pictures: [
            {
                /*playerId: [6],
                title: "Match action",
                tag: ["Match"],
                src: shadow,
                legend: "Khalid Moore en pleine action lors d'un match",
                date: "",
                link: "",
                author: "",
                persons: []*/
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }
]

