export const matchsData = [
  {
    id: 1,
    date: "20",
    hours: "",
    month: "Septembre",
    year: "2024",
    fullDate: "9/20/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 4,
    homeBasedTeamScore: "68",
    outsideTeamScore: "66",
  },
  {
    id: 2,
    date: "28",
    hours: "",
    month: "Septembre",
    year: "2024",
    fullDate: "9/28/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 17,
    homeBasedTeamScore: "69",
    outsideTeamScore: "83",
  },
  {
    id: 3,
    date: "01",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/01/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 20,
    homeBasedTeamScore: "84",
    outsideTeamScore: "54",
  },
  {
    id: 4,
    date: "06",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/06/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 9,
    homeBasedTeamScore: "104",
    outsideTeamScore: "96",
  },
  {
    id: 5,
    date: "11",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/11/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 14,
    homeBasedTeamScore: "82",
    outsideTeamScore: "59",
  },
  {
    id: 6,
    date: "15",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/15/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 21,
    homeBasedTeamScore: "84",
    outsideTeamScore: "74",
  },
  {
    id: 7,
    date: "19",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/19/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 22,
    homeBasedTeamScore: "57",
    outsideTeamScore: "94",
  },
  {
    id: 8,
    date: "27",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/27/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 6,
    homeBasedTeamScore: "63",
    outsideTeamScore: "77",
  },
  {
    id: 9,
    date: "29",
    hours: "",
    month: "Octobre",
    year: "2024",
    fullDate: "10/29/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 23,
    homeBasedTeamScore: "61",
    outsideTeamScore: "71",
  },
  {
    id: 10,
    date: "02",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/02/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 11,
    homeBasedTeamScore: "95",
    outsideTeamScore: "102",
  },
  {
    id: 11,
    date: "10",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/10/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 3,
    homeBasedTeamScore: "65",
    outsideTeamScore: "97",
  },
  {
    id: 12,
    date: "13",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/13/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 23,
    homeBasedTeamScore: "96",
    outsideTeamScore: "57",
  },
  {
    id: 13,
    date: "16",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/16/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 13,
    homeBasedTeamScore: "85",
    outsideTeamScore: "91",
  },
  {
    id: 14,
    date: "29",
    hours: "",
    month: "Novembre",
    year: "2024",
    fullDate: "11/29/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 8,
    homeBasedTeamScore: "83",
    outsideTeamScore: "86",
  },
  {
    id: 15,
    date: "07",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/07/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 7,
    homeBasedTeamScore: "91",
    outsideTeamScore: "63",
  },
  {
    id: 16,
    date: "11",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/11/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 20,
    homeBasedTeamScore: "75",
    outsideTeamScore: "86",
  },
  {
    id: 17,
    date: "14",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/14/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 10,
    homeBasedTeamScore: "72",
    outsideTeamScore: "67",
  },
  {
    id: 18,
    date: "18",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/18/2024",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 21,
    homeBasedTeamScore: "67",
    outsideTeamScore: "93",
  },
  {
    id: 19,
    date: "21",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/21/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 18,
    homeBasedTeamScore: "71",
    outsideTeamScore: "54",
  },
  {
    id: 20,
    date: "27",
    hours: "",
    month: "Décembre",
    year: "2024",
    fullDate: "12/27/2024",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 12,
    homeBasedTeamScore: "96",
    outsideTeamScore: "85",
  },
  {
    id: 21,
    date: "11",
    hours: "",
    month: "Janvier",
    year: "2025",
    fullDate: "01/11/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 24,
    homeBasedTeamScore: "81",
    outsideTeamScore: "70",
  },
  {
    id: 22,
    date: "15",
    hours: "",
    month: "Janvier",
    year: "2025",
    fullDate: "01/15/2025",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: true,
    opponentId: 25,
    homeBasedTeamScore: "90",
    outsideTeamScore: "79",
  },
  {
    id: 23,
    date: "18",
    hours: "",
    month: "Janvier",
    year: "2025",
    fullDate: "01/18/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 7,
    homeBasedTeamScore: "83",
    outsideTeamScore: "87",
  },
  {
    id: 24,
    date: "21",
    hours: "",
    month: "Janvier",
    year: "2025",
    fullDate: "01/21/2025",
    competition: "BASKETBALL CHAMPIONS LEAGUE",
    resume: "",
    isHome: false,
    opponentId: 25,
    homeBasedTeamScore: "75",
    outsideTeamScore: "73",
  },
  {
    id: 25,
    date: "25",
    hours: "",
    month: "Janvier",
    year: "2025",
    fullDate: "01/25/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 18,
    homeBasedTeamScore: "56",
    outsideTeamScore: "58",
  },
  {
    id: 26,
    date: "01",
    hours: "",
    month: "Février",
    year: "2025",
    fullDate: "02/01/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 9,
    homeBasedTeamScore: "83",
    outsideTeamScore: "74",
  },
  {
    id: 27,
    date: "08",
    hours: "",
    month: "Février",
    year: "2025",
    fullDate: "02/08/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 12,
    homeBasedTeamScore: "81",
    outsideTeamScore: "83",
  },
  {
    id: 28,
    date: "11",
    hours: "",
    month: "Février",
    year: "2025",
    fullDate: "02/11/2025",
    competition: "Coupe de france",
    resume: "",
    isHome: true,
    opponentId: 6,
    homeBasedTeamScore: "54",
    outsideTeamScore: "76",
  },
  {
    id: 29,
    date: "14",
    hours: "",
    month: "Février",
    year: "2025",
    fullDate: "02/14/2025",
    competition: "Leaders Cup",
    resume: "",
    isHome: false,
    opponentId: 3,
    homeBasedTeamScore: "86",
    outsideTeamScore: "92",
  },
  {
    id: 30,
    date: "15",
    hours: "",
    month: "Février",
    year: "2025",
    fullDate: "02/15/2025",
    competition: "Leaders Cup",
    resume: "",
    isHome: false,
    opponentId: 17,
    homeBasedTeamScore: "86",
    outsideTeamScore: "92",
  },
  {
    id: 31,
    date: "01",
    hours: "",
    month: "Mars",
    year: "2025",
    fullDate: "03/01/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 3,
    homeBasedTeamScore: "86",
    outsideTeamScore: "88",
  },
  {
    id: 32,
    date: "08",
    hours: "",
    month: "Mars",
    year: "2025",
    fullDate: "03/08/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 24,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 33,
    date: "23",
    hours: "",
    month: "Mars",
    year: "2025",
    fullDate: "03/23/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 11,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 34,
    date: "29",
    hours: "",
    month: "Mars",
    year: "2025",
    fullDate: "03/29/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 13,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 35,
    date: "05",
    hours: "",
    month: "Avril",
    year: "2025",
    fullDate: "04/05/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 6,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 36,
    date: "12",
    hours: "",
    month: "Avril",
    year: "2025",
    fullDate: "04/12/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 4,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 37,
    date: "19",
    hours: "",
    month: "Avril",
    year: "2025",
    fullDate: "04/19/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 14,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 38,
    date: "27",
    hours: "",
    month: "Avril",
    year: "2025",
    fullDate: "04/27/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 8,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 39,
    date: "03",
    hours: "",
    month: "Mai",
    year: "2025",
    fullDate: "05/03/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 17,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 40,
    date: "10",
    hours: "",
    month: "Mai",
    year: "2025",
    fullDate: "05/10/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 22,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  {
    id: 41,
    date: "17",
    hours: "",
    month: "Mai",
    year: "2025",
    fullDate: "05/17/2025",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 10,
    homeBasedTeamScore: "",
    outsideTeamScore: "",
  },
  
];
