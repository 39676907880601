const HandleArticleCoverImg = (imgArray, type, size) => {
    if (Array.isArray(imgArray) && imgArray.length > 0) {
        const img = imgArray.find(img => img && img.type === type);
        if (img && img.media_details && img.media_details.sizes && img.media_details.sizes[size]) {
            return img.media_details.sizes[size]["source_url"];
        }
    }
    return null;
};

export default HandleArticleCoverImg;
